import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useUserId from '../hook/userUserId';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { toast } from "react-toastify";
import { CreateDebtGroupDTO } from '../service/debt-api/Models';
import { createDebtGroup } from '../service/debt-api/debtGroupService';


interface AddDebtGroupDialogProps {
    open: boolean;
    onClose: () => void;
}

const validationSchema = yup.object({
    debtGroupName: yup
        .string()
        .min(3, 'En az 3 harfli olmalı')
        .required('Grup adı zorunludur'),
    pass: yup
        .string()
        .required('Şifre zorunludur')
});

const AddDebtGroupDialog: React.FC<AddDebtGroupDialogProps> = ({ open, onClose }) => {
    const [loading, setLoading] = React.useState(false);
    const userId = useUserId();

    const formik = useFormik({
        initialValues: {
            debtGroupName: '',
            pass: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const data: CreateDebtGroupDTO = {
                userId: userId!,
                name: values.debtGroupName,
                password: values.pass
            };

            const debtGroupId = await createDebtGroup(data);

            setLoading(false);

            if (debtGroupId) {
                toast.success("Grup başarıyla oluşturuldu. Ana sayfadan linki paylaşabilirsin", {
                    hideProgressBar: true
                });

                handleClose();
            }

        }
    });

    const handleClose = () => {
        setLoading(false);
        formik.resetForm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>Borç Grubu Ekle</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="debtGroupName"
                        name="debtGroupName"
                        label="Grup Adı"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={formik.values.debtGroupName}
                        onChange={formik.handleChange}
                        error={formik.touched.debtGroupName && Boolean(formik.errors.debtGroupName)}
                        helperText={formik.touched.debtGroupName && formik.errors.debtGroupName}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="pass"
                        name="pass"
                        label="Grup Şifresi"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={formik.values.pass}
                        onChange={formik.handleChange}
                        error={formik.touched.pass && Boolean(formik.errors.pass)}
                        helperText={formik.touched.pass && formik.errors.pass}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Vazgeç
                    </Button>
                    <Button type='submit' color="primary">
                        Onayla
                    </Button>
                </DialogActions>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
};

export default AddDebtGroupDialog;
