import yaml from 'yaml';

interface Config {
    authApiUrl: string;
    debtApiUrl: string;
}

interface EnvConfig {
    development: Config;
    production: Config;
}

async function loadConfig(): Promise<Config> {
    const response = await fetch('/config.yaml');
    const text = await response.text();
    const config: EnvConfig = yaml.parse(text);

    const env = process.env.NODE_ENV || 'development';

    if (!(env in config)) {
        throw new Error(`Configuration for environment "${env}" is missing in config.yaml`);
    }

    return config[env as keyof EnvConfig];
}

export default loadConfig;
