import React, { useEffect, useState, useCallback } from 'react';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import AddDebtGroupDialog from '../components/AddDebtGroupDialog';
import useUserId from '../hook/userUserId';
import { DebtGroupDTO } from '../service/debt-api/Models';
import { getDebtGroupsByUser } from '../service/debt-api/debtGroupService';
import DebtGroupCard from '../components/DebtGroupCard';

const HomePage: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const userId = useUserId();
    const [addDebtGroupDialogOpen, setAddDebtGroupDialogOpen] = useState(false);
    const [groups, setGroups] = useState<DebtGroupDTO[] | null>(null);

    const loadReport = useCallback(async () => {
        if (userId) {
            setLoading(true);
            try {
                const data = await getDebtGroupsByUser(userId);
                setGroups(data);
            } catch (error) {
                console.error("Error fetching groups:", error);
            } finally {
                setLoading(false);
            }
        }
    }, [userId]);

    const handleGroupDeleted = () => {
        loadReport();
    };

    useEffect(() => {
        loadReport();
    }, [loadReport]);

    const handleClickOpen = () => {
        setAddDebtGroupDialogOpen(true);
    };

    const handleClose = () => {
        setAddDebtGroupDialogOpen(false);
        loadReport();
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>Yeni Grup</Button>
            <AddDebtGroupDialog open={addDebtGroupDialogOpen} onClose={handleClose} />
            {groups && <DebtGroupCard debtGroups={groups} onGroupDeleted={handleGroupDeleted} />}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default HomePage;
