import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../service/auth-api/authService';
import { Backdrop, CircularProgress } from '@mui/material';
import { RegisterUserDTO } from '../service/auth-api/Models';
import { toast } from "react-toastify";


const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('İsim zorunludur'),
    lastName: yup
        .string()
        .required('Soyisim zorunludur'),
    email: yup
        .string()
        .email('Geçerli bir email adresi girin')
        .required('Email adresi zorunludur'),
    password: yup
        .string()
        .min(8, 'Parola en az 8 karakter olmalıdır')
        .required('Parola zorunludur'),
    passwordRepeat: yup
        .string()
        .oneOf([yup.ref('password')], 'Parolalar aynı olmalıdır')
        .required('Parola tekrarı zorunludur')
});

export default function SignUpPage() {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordRepeat: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const user: RegisterUserDTO = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
            };
            const result = await registerUser(user);

            setLoading(false);

            if (result) {
                toast.success("Kayıt başarılı. Giriş yapabilirsin.", {
                    hideProgressBar: true
                });
                navigate('/login');
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <img
                        src="/goztepe.png"
                        alt="Goztepe"
                        style={{ width: 30, height: 25 }}
                    />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Kayıt Ol
                </Typography>
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="İsim"
                                autoFocus
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Soyisim"
                                name="lastName"
                                autoComplete="family-name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Adresi"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Parola"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="passwordRepeat"
                                label="Parola Tekrar"
                                type="password"
                                id="passwordRepeat"
                                autoComplete="new-password"
                                value={formik.values.passwordRepeat}
                                onChange={formik.handleChange}
                                error={formik.touched.passwordRepeat && Boolean(formik.errors.passwordRepeat)}
                                helperText={formik.touched.passwordRepeat && formik.errors.passwordRepeat}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Kayıt Ol
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Zaten hesabın var mı? Giriş Yap
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
