import React from 'react';
import { Box, Button, Grid, Typography, Paper, Card, CardContent } from '@mui/material';

interface PersonDebt {
  name: string;
  amount: number;
  type: 'debt' | 'credit';
}

const Dashboard: React.FC = () => {
  const totalDebt = 700;
  const totalCredit = 1500;
  const personDebts: PersonDebt[] = [
    { name: 'Kıvanç', amount: 300, type: 'credit' },
    { name: 'Cem', amount: 450, type: 'credit' },
    { name: 'Burcu', amount: 500, type: 'debt' },
  ];

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Ana Sayfa
      </Typography>

      <Grid container spacing={3}>
        {/* Özet Bilgiler */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h6" gutterBottom>
              Toplam Durum
            </Typography>
            <Typography variant="h5" color="textPrimary">
              Toplam Borç: {totalDebt} TL
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Toplam Alacak: {totalCredit} TL
            </Typography>
          </Paper>
        </Grid>

        {/* Kişi Bazlı Borç ve Alacaklar */}
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Kişi Bazlı Borç ve Alacak
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxHeight: '600px',
              overflowY: 'auto',
            }}
          >
            {personDebts.map((person, index) => (
              <Card key={index} sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h6">{person.name}</Typography>
                  <Typography variant="body1">
                    {person.amount} TL {person.type === 'debt' ? 'Borçlu' : 'Alacaklı'}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>

        {/* Aksiyon Butonları */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" fullWidth sx={{ mb: 2 }}>
              Yeni Borç Grubu Oluştur
            </Button>
            <Button variant="outlined" color="secondary" fullWidth>
              Diğer İşlemler
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
