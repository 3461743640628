import { toast } from "react-toastify";
import loadConfig from "../../utils/configLoader";
import { ErrorModel, RegisterUserDTO, SigninDTO, SigninResponseDTO, SuccessResult } from "./Models";

const prefix = "/Auth"

export const signIn = async (user: SigninDTO): Promise<string | null> => {
    try {
        const config = await loadConfig();
        const response = await fetch(`${config.authApiUrl + prefix}/signIn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
            const resp: SigninResponseDTO = await response.json();
            return resp.token;
        } else {
            const err: ErrorModel = await response.json();
            toast.error(err.message);
            return null
        }
    } catch (error) {
        toast.error("Bilinmeyen bir hata oluştu");
        return null;
    }
};

export const registerUser = async (user: RegisterUserDTO): Promise<boolean | null> => {
    try {
        const config = await loadConfig();
        const response = await fetch(`${config.authApiUrl + prefix}/registerUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
            const resp: SuccessResult = await response.json();

            return resp.success;
        } else {
            const err: ErrorModel = await response.json();
            toast.error(err.message);
            return null
        }
    } catch (error) {
        toast.error("Bilinmeyen bir hata oluştu");
        return null;
    }
};
