import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledDrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

interface DrawerHeaderProps {
    onClose: () => void;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ onClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleNavigateHome = () => {
        navigate('/');
    };

    return (
        <StyledDrawerHeader>
            <img src="/file.png" width="50px" alt="Logo" style={{ flex: 0, cursor: 'pointer' }} onClick={handleNavigateHome} />
            <Typography variant="h6" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleNavigateHome}>
                Debt
            </Typography>
            <IconButton onClick={onClose} style={{ flex: 0 }}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        </StyledDrawerHeader>
    );
};

export default DrawerHeader;
