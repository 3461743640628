import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageIcon from '@mui/icons-material/InsertDriveFile';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';

const pages = [
    { text: 'Ana Sayfa', icon: <HomeIcon />, path: '/' },
    { text: 'Another Page', icon: <PageIcon />, path: '/another' },
    { text: 'Çıkış Yap', icon: <Logout />, path: '/logout' },
];

interface DrawerListProps {
    open: boolean;
    handleDrawerClose: () => void;
}

const DrawerList: React.FC<DrawerListProps> = ({ open, handleDrawerClose }) => {
    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
        handleDrawerClose()
    };

    return (
        <List>
            {pages.map(({ text, icon, path }) => (
                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }} onClick={() => handleNavigation(path)}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center'
                            }}>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default DrawerList;
export { pages }; 
