import React, { useState } from 'react';
import {
    Card, CardContent, Typography, List, ListItem, ListItemAvatar, Avatar,
    Grid, ListItemText, IconButton, Menu, MenuItem, ListItemIcon,
    Backdrop,
    CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Delete, Share, Visibility } from '@mui/icons-material';
import { DebtGroupDTO } from '../service/debt-api/Models';
import YesNoDialog from './YesNoDialog';
import { deleteDebtGroup } from '../service/debt-api/debtGroupService';

interface DebtGroupCardProps {
    debtGroups: DebtGroupDTO[];
    onGroupDeleted: () => void;
}

const DebtGroupCard: React.FC<DebtGroupCardProps> = ({ debtGroups, onGroupDeleted }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState<'delete' | null>(null);
    const [loading, setLoading] = React.useState(false);

    const handleDialogOpen = (type: 'delete', groupId: string) => {
        setDialogType(type);
        setCurrentGroupId(groupId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirm = async () => {
        if (currentGroupId && dialogType === "delete") {
            setLoading(true);
            try {
                const data = await deleteDebtGroup(currentGroupId);
                onGroupDeleted();
            } catch (error) {
                console.error("Error delete:", error);
            } finally {
                setLoading(false);
            }
        }
        setDialogOpen(false);
    };

    const handleShare = () => {
        console.log("Share: " + currentGroupId)
    }

    const handleView = () => {
        console.log("View: " + currentGroupId)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setAnchorEl(event.currentTarget);
        setCurrentGroupId(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
                {debtGroups.map(group => (
                    <Grid item xs={12} sm={6} md={4} key={group.id}>
                        <Card elevation={3} style={{ position: 'relative' }}>
                            <IconButton
                                aria-label="settings"
                                onClick={(event) => handleClick(event, group.id)}
                                style={{ position: 'absolute', right: 8, top: 8 }}>
                                <MoreVertIcon />
                            </IconButton>
                            <CardContent>
                                <Typography variant="h6" component="h2">
                                    Grup Adı: {group.name}
                                </Typography>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentGroupId === group.id}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleView}>
                                        <ListItemIcon>
                                            <Visibility fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Detay Gör" />
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDialogOpen('delete', group.id)}>
                                        <ListItemIcon>
                                            <Delete fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Sil" />
                                    </MenuItem>
                                    <MenuItem onClick={handleShare}>
                                        <ListItemIcon>
                                            <Share fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Paylaş" />
                                    </MenuItem>
                                </Menu>
                                <Typography color="textSecondary" variant="caption" gutterBottom>
                                    Şifre: {group.password}
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    Üyeler:
                                    <List>
                                        {group.users.map(user => (
                                            <ListItem key={user.userId}>
                                                <ListItemAvatar>
                                                    <Avatar>{user.firstName[0] + user.lastName[0]}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={user.fullName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <YesNoDialog
                open={dialogOpen}
                title={dialogType === 'delete' ? "Grubu Sil?" : "Grubu Paylaş?"}
                description={`Bu grubu ${dialogType === 'delete' ? 'silmek' : 'paylaşmak'} istediğinizden emin misiniz? Bu işlem geri alınamaz.`}
                onConfirm={handleDialogConfirm}
                onCancel={handleDialogClose}
                onClose={handleDialogClose} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default DebtGroupCard;
