import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { signIn } from '../service/auth-api/authService';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { SigninDTO } from '../service/auth-api/Models';
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from '@mui/material';


const validationSchema = yup.object({
    email: yup
        .string()
        .email('Geçerli bir email adresi girin')
        .required('Email adresi zorunludur'),
    password: yup
        .string()
        .min(8, 'Parola en az 8 karakter olmalıdır')
        .required('Parola zorunludur')
});

export default function LoginPage() {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const user: SigninDTO = {
                email: values.email,
                password: values.password,
                rememberMe: true
            };

            const token = await signIn(user);

            setLoading(false);

            if (token) {
                toast.success("Giriş başarılı", {
                    hideProgressBar: true
                });
                localStorage.setItem('token', token);
                navigate(from, { replace: true });
            }
        }
    });

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate(from, { replace: true });
        }
    }, [navigate, from]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <img
                        src="/goztepe.png"
                        alt="Goztepe"
                        style={{ width: 30, height: 25 }}
                    />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Giriş Yap
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        autoFocus
                        required
                        fullWidth
                        id="email"
                        label="Email Adresi"
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Parola"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Giriş
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Şifreni mi unuttun?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Hesabın mı yok? Kayıt ol"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
