import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface YesNoDialogProps {
    open: boolean;
    title: string;
    description: string;
    onConfirm: () => void;
    onCancel: () => void;
    onClose: () => void;
}

const YesNoDialog: React.FC<YesNoDialogProps> = ({ open, title, description, onConfirm, onCancel, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="error">
                    Hayır
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Evet
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default YesNoDialog;
