import { toast } from "react-toastify";
import loadConfig from "../../utils/configLoader";
import { ErrorModel, CreateDebtGroupDTO, DebtGroupDTO } from "./Models";

const prefix = "/debtGroup"

export const createDebtGroup = async (user: CreateDebtGroupDTO): Promise<string | null> => {
    try {
        const config = await loadConfig();
        const response = await fetch(`${config.debtApiUrl + prefix}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
            const resp: string = await response.json();

            return resp;
        } else {
            const err: ErrorModel = await response.json();
            toast.error(err.message);
            return null
        }
    } catch (error) {
        toast.error("Bilinmeyen bir hata oluştu");
        return null;
    }
};

export const getDebtGroupsByUser = async (userId: string): Promise<DebtGroupDTO[] | null> => {
    try {
        const config = await loadConfig();
        const response = await fetch(`${config.debtApiUrl + prefix}/user/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (response.ok) {
            const resp: DebtGroupDTO[] = await response.json();
            return resp;
        } else {
            const err: ErrorModel = await response.json();
            if (err.message) {
                toast.error(err.message);
            }
            else {
                toast.error("Bilinmeyen bir hata oluştu")
            }
            return null
        }
    } catch (error) {
        toast.error("Bilinmeyen bir hata oluştu");
        return null;
    }
};

export const deleteDebtGroup = async (debtGroupId: string): Promise<boolean> => {
    try {
        const config = await loadConfig();
        const response = await fetch(`${config.debtApiUrl + prefix}/${debtGroupId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (response.ok) {
            return true;
        } else {
            const err: ErrorModel = await response.json();
            if (err.message) {
                toast.error(err.message);
            }
            else {
                toast.error("Bilinmeyen bir hata oluştu")
            }
            return false;
        }
    } catch (error) {
        toast.error("Bilinmeyen bir hata oluştu");
        return false;
    }
};
