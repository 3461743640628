import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DrawerLayout from './layout/DrawerLayout';
import ProtectedRoute from './components/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoutPage from './pages/Logout';
import SignUpPage from './pages/SignUpPage';
import Dashboard from './pages/AnotherPage';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

const App: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer></ToastContainer>
      <Router>
        <Routes>
          <Route path="/" element={
            <DrawerLayout
              drawerOpen={drawerOpen}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose} />}>

            <Route index element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
            <Route path="another" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
