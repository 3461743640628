import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage() {
    const navigate = useNavigate();

    React.useEffect(() => {
        localStorage.removeItem('token');
        navigate('/login');
    }, [navigate]);

    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Çıkış yapılıyor...'}
        </Typography>
    );
}
